import axios from 'axios';
import { getToken } from '@/services/user-service';

export const getSchemas = async () => {
    const schemas = (await axios.get('/api/v0/schema', {
        headers: {
            'Authorization': `Bearer ${getToken()}`
        }
    }))['data']['data'];

    return schemas;
};

export const getSchemaVersions = async (namespace, name) => {
    const versions = (await axios.get(`/api/v0/schema/namespace/${namespace}/name/${name}`, {
        headers: {
            'Authorization': `Bearer ${getToken()}`
        }
    }))['data']['data'];

    return versions;
};
