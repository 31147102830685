<template lang="pug">
    .version
        .timestamp.animated.fadeIn.inline-block {{ formatDate(schema['created_at']) }}
        .content.inline-block
            .card.animated.fadeIn
                p.version.inline-block Version: {{ schema['version'] }}
                i.icon.material-icons-outlined.inline-block more_horiz
                p.timestamp.inline-block {{ formatDate(schema['created_at']) }}
                .fields
                    .field.inline-block(v-for="f in schema['definition']['fields']")
                        i.icon.material-icons-outlined.inline-block(:class="f['logicalType'] || f['type']") {{ getFieldIcon(f['logicalType'] || f['type']) }}
                        label {{ f['name'] }}
</template>

<script>
export default {
    props: {
        schema: {
            type: Object
        }
    },
    methods: {
        getFieldIcon(type) {
            switch(type) {
                case 'string':
                    // return 'format_size';
                    return 'text_format';
                case 'timestamp-millis':
                    return 'schedule';
                case 'int':
                case 'double':
                case 'float':
                    // return 'format_size';
                    return '#';
                default:
                    return 'code';
            }
        },
        formatDate(timestamp) {
            const pad = int => int >= 10 ? int : `0${int}`;
            const d = new Date(timestamp);

            const year = d.getFullYear();
            let month = pad(d.getMonth());
            let date = pad(d.getDate());
            let hour = pad(d.getHours());
            let minutes = pad(d.getHours());

            return `${year}-${month}-${date} ${hour}:${minutes}`;
        }
    }
};
</script>

<style scoped>

.version > * {
    vertical-align: top;
}

.version .timestamp,
.version .content {
    padding-top: var(--padding-medium);
}

.version .timestamp {
    font-size: var(--font-size-small);
    color: var(--text-color-grey);
    font-weight: 400;
    padding-right: var(--padding-medium);
}

.version .content {
    border-left: 2px solid #ccc;
    padding-left: var(--padding-medium);
    padding-right: var(--padding-base);
    padding-bottom: var(--padding-large);
}

.version .content .card {
    padding: var(--padding-base);
    border: 1px solid #ccc;
    border-radius: 6px;
    box-shadow: 0 4px 8px rgba(64, 64, 64, .08);
    width: 400px;
}
.version .content .card .timestamp {
    display: none;
    width: 100%;
    font-size: var(--font-size-tiny);
    padding: 0;
}

.version .content p.version {
    width: 90%;
    margin: 0;
    font-weight: 600;
    margin-bottom: var(--margin-large);
}

.version .content .card > i.icon {
    width: 10%;
    text-align: right;
    cursor: pointer;
    color: var(--text-color-grey);
    top: calc(var(--padding-base) * -.4);
    vertical-align: top;
}

.version .content::before {
    content: "";
    position: absolute;
    display: block;
    width: 8px;
    height: 8px;
    background: #242424;
    border: 6px solid #fff;
    border-radius: 100px;
    left: -11px;
}

.field {
    width: 33%;
    margin-bottom: 8px;
}

.field > * {
    vertical-align: middle;
}

.field i {
    font-size: 14px;
    opacity: .5;
    margin-top: 1px;
    width: 21px;
    line-height: 17px;
}
.field i.string {
    font-size: 17px;
}
.field i.int,
.field i.float,
.field i.double {
    padding-left: 3px;
    font-size: 16px;
    margin-top: -2px;
    font-weight: bold;
}

.field label {
    font-family: monospace;
    font-size: var(--font-size-small);
    width: calc(100% - 21px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 6px;
}

@media only screen and (max-width: 700px) {
    .version .content {
        border-left: none;
        padding: var(--padding-medium) 0 0 0;
        width: 100%;
    }
    .version .content .card {
        width: 100%;
    }
    
    .version .content::before {
        display: none;
    }
    .field {
        width: 50%;
    }
    
    .version > .timestamp {
        display: none;
    }
    .version .content .card .timestamp {
        display: block;
        margin-bottom: var(--margin-large);
    }

    .version .content p.version {
        margin-bottom: 0;
    }
}
</style>