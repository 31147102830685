<template lang="pug">
section#schema-details
    header
        router-link.back(:to="{ name: 'dashboard' }")
            i.icon.material-icons-outlined.inline-block navigate_before
            span.inline-block Back

        .property.name
            label name
            p {{ schemaName }}

        .property.owner
            label owner
            p {{ schemaOwner }}

        .property.namespace
            label namespace
            p {{ schemaNamespace }}

        .property.created_at
            label Last updated
            p {{ schemaLastUpdated }}

    h2.animated.fadeIn Versions

    .version(v-for="v in versions")
        SchemaVersion(:schema="v", :key="v ? v['id'] : undefined")

</template>

<script>
import { getUserDetails } from '@/services/user-service';
import { getSchemaVersions } from '@/services/schema-service';
import SchemaVersion from '@/components/SchemaVersion';

export default {
    components: { SchemaVersion },
    data: () => ({
        versions: undefined,
        user: undefined
    }),
    computed: {
        schemaName: function () {
            return this.$route.params.schemaName;
        },
        schemaNamespace: function () {
            return this.$route.params.namespace;
        },
        schemaOwner: function () {
            return this.versions ? this.versions[0]['owner'] : '--';
        },
        schemaLastUpdated: function () {
            return this.versions ? this.versions[0]['created_at'] : '--';
        }
    },
    async mounted() {
        try {
            this._setUserInfo();
            const versionsData = await getSchemaVersions(this.schemaNamespace, this.schemaName);
            this.versions = versionsData;
        } catch (error) {
            console.error(error);
        }
    },
    methods: {
        _addSchemaVersion(newSchema) {
            try {
                this.versions = [ newSchema, ...this.versions ];
            } catch (error) {
                // ignored
            }
        },
        async _setUserInfo() {
            try {
                const userDetails = await getUserDetails();
                this.user = userDetails;

                this.sockets.subscribe(`new-schema::${this.user['username']}`, (newSchema) => {
                    this._addSchemaVersion(newSchema);
                });
            } catch (error) {
                this.user = undefined;
                console.error(error);
            }
        }
    }
};
</script>
<style scoped>
header .back {
    padding: var(--padding-base) 0;
    padding-right: var(--padding-base);
    position: relative;
    display: block;
    width: fit-content;
    top: calc(var(--padding-base) * -1);
}
header .back > *  {
    vertical-align: middle;
}
header .back i.icon {
    font-size: var(--font-size-base);
    margin-left: -6px;
}
header .back span {
    text-transform: uppercase;
    font-size: var(--font-size-small);
    font-weight: bold;
}

h2 {
    font-size: var(--font-size-huge);
    margin: var(--margin-large) 0;
}

.property {
    margin-bottom: var(--margin-base);
    width: 50%;
    display: inline-block;
    position: relative;
} 

.property label {
    text-transform: uppercase;
    color: var(--text-color-grey);
    font-weight: bold;
    font-size: var(--font-size-tiny);
}

.property p {
    margin: 0;
    font-size: var(--font-size-medium);
    font-weight: 300;
}

@media only screen and (max-width: 700px) {    
    h2 {
        font-size: var(--font-size-medium);
        margin: var(--margin-base) 0;
        margin-bottom: 0;
    }
    .property {
        width: 100%;
    }
    .property p {
        font-size: var(--font-size-base);
    }
}
</style>
